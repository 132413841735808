$showMediaLabel: false;
.loginpage__info, .loginpage__form-container {
  margin-top: rem(80);
  grid-column: span 12;

  @include mq(desktop-xs) {
    margin-top: rem(40);
    grid-column: span 6;
  }

  @include mq(desktop-m) {
    margin-top: 0;
  }
}

.loginpage__intro, .loginpage__subtitle, .loginpage__sub-links {
  margin-top: rem(30);
}

.loginpage__sub-links {
  display: flex;
  gap: rem(20);
  justify-content: center;
}

.loginpage__text, .loginpage__terms, .loginpage__appointment-button {
  margin-top: rem(60);
}

.loginpage__form-container {
  border: 1px solid color(border);
  border-radius: radius(xlarge);
  align-self: start;
  display: grid;
  padding: rem(40);

  @include mq(desktop-xs) {
    padding: rem(60);
  }

  @include mq(desktop-m) {
    padding: rem(80);
  }
}

.auth__methods {
  display: flex;
  gap: rem(30);
  justify-content: center;
  border-bottom: rem(1) solid color(border);
  margin-bottom: rem(40);
  @include mqOnly(desktop-xs) {
    gap: rem(40);
  }
}

.auth__method-btn {
  color: color(headline);
  font-weight: 400;
  min-height: rem(50);
  &:hover {
    color: color(primary, default);
    border-bottom: rem(2) solid color(primary, default);
  }

}

.auth__method--selected {
  color: color(primary, default);
  border-bottom: rem(2) solid color(primary, default);
}

.auth-form__info {
  margin-top: rem(30);
  text-align: center;
}
.auth-form__info-text {
  font-weight: 400 !important;
}
