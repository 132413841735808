$showMediaLabel: false;
////
/// @group Media Queries
////


/// Breakpoints variable map
/// @type Map
$breakpoints: (
	phone-xs: 0,
	phone-s: rem(321),
	phone-m: rem(426),
	tablet: rem(576),
	desktop-xs: rem(775),
	desktop-s: rem(992),
	desktop-m: rem(1200),
	desktop-l: rem(1280),
	desktop-xl: rem(1367),
	desktop-xxl: rem(1740),
	ultrawide: rem(1900),
	4k: rem(2560),
);

@function breakpoint($key) {
	@return map-get($breakpoints, $key);
}

// Support functions and mixins
@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - rem(.02), null);
}

@function breakpoint-infix($name, $breakpoints: $breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  }
  @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    /* stylelint-disable-next-line */
    @media (max-width: $max) {
      @content;
    }
  }
  @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    /* stylelint-disable-next-line */
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
  @else if $max == null {
    @include media-breakpoint-up($lower) {
      @content;
    }
  }
  @else if $min == null {
    @include media-breakpoint-down($upper) {
      @content;
    }
  }
}

// Queries

/// Main media query.
/// All media queries are mobile-first so they break up and not down.
/// @param {String} $name [undefined] - The name of the breakpoint, e.g. phone-s.
/// @param {Map} $breakpoints [$breakpoints] - The map of breakpoints to use, optional.
/// @example @include mq(desktop-l) { ... }
@mixin mq($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

/// Media query for only one breakpoint.
/// All media queries are mobile-first so they break up and not down.
/// @param {String} $name [undefined]  - The name of the breakpoint, e.g. desktop-s.
/// @param {Map} $breakpoints [$breakpoints] - The map of breakpoints to use, optional.
/// @example @include mqOnly(desktop-l) { ... }
@mixin mqOnly($name, $breakpoints: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

/// Media query for inbetween breakpoints.
/// All media queries are mobile-first so they break up and not down.
/// 
/// @param {String} $name [undefined] - The name of the breakpoint, e.g. (tablet, desktop-l).
/// @param {Map} $breakpoints [$breakpoints] - The map of breakpoints to use, optional.
/// @example  @include mqBetween(desktop-l) { ... }
@mixin mqBetween($lower, $upper, $breakpoints: $breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    /* stylelint-disable-next-line */
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
  @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  }
  @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media label
@if $showMediaLabel == true {
  body::after {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999;
    padding: rem(10) rem(9) rem(6) rem(13);
    font-size: rem(10);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: rem(2);

    @include mq(phone-xs) {
      content: 'phone-xs';
      color: #fff;
      background: #6200ea;
    }

    @include mq(phone-s) {
      content: 'phone-s';
      background: #6200ea;
    }

    @include mq(phone-m) {
      content: 'phone-m';
      background: #6200ea;
    }

    @include mq(tablet) {
      content: 'tablet';
      background: #d50000;
    }

    @include mq(desktop-xs) {
      content: 'desktop-xs';
      color: #fff;
      background: #263238;
    }

    @include mq(desktop-s) {
      content: 'desktop-s';
      background: #263238;
    }

    @include mq(desktop-m) {
      content: 'desktop-m';
      background: #263238;
    }

    @include mq(desktop-l) {
      content: 'desktop-l';
      background: #263238;
    }

    @include mq(desktop-xl) {
      content: 'desktop-xl';
      background: #263238;
    }

    @include mq(desktop-xxl) {
      content: 'desktop-xxl';
      background: #263238;
    }

    @include mq(ultrawide) {
      content: 'ultrawide';
      background: #263238;
    }
  }
}
