$showMediaLabel: false;
.form-box {
  border: 1px solid color(border);
  border-radius: radius(xlarge);
  align-self: start;
  display: grid;
}

.form-box__input-group {
  margin-bottom: rem(30);
}

.form-box__inner {
  padding: rem(40);

  @include mq(desktop-xs) {
    padding: rem(60) rem(60) rem(80) rem(60);
  }

  @include mq(desktop-m) {
    padding: rem(80) rem(80) rem(120) rem(80);
  }
}

.form-box__input-group--hidden {
  display: none;
}