$showMediaLabel: false;
// Font loaded from [src/assets/fonts] folder

@font-face {
  font-family: 'GT America';  
  font-weight: weight(light);
  font-style: normal;  
  font-display: block; /* Read next point */  
  unicode-range: U+000-5FF; /* Download only latin glyphs */  
  src: local('GT America'),
  url('assets/fonts/GT-America-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'GT America';  
  font-weight: weight(medium);
  font-style: normal;  
  font-display: block; /* Read next point */  
  unicode-range: U+000-5FF; /* Download only latin glyphs */  
  src: local('GT America'),
  url('assets/fonts/GT-America-Medium.woff2') format('woff2');
}
