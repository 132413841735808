$showMediaLabel: false;
.table {
  border-top: 0;
  border-left: 0;
  margin-bottom: gutter(m);
  display: table;
  overflow-x: auto;
  white-space: nowrap;
  min-width: 100%;

  @include mq(tablet) {
    margin-bottom: gutter(l);
  }

  tr, .tr {
    border-top: rem(1) solid color(border);

    &:hover {
      background: lighten(color(border), 11%);
    }
  }

  td {
    display: table-cell;
    max-width: rem(300);
    white-space: pre-wrap;
  }

  td, th {
    padding: rem(15) rem(20);
  }

  th, .th {
    font-weight: weight(bold);
    background: color(darkBlue);
    color: color(teal);
    pointer-events: none;
  }

  tr.th {
    &:hover {
      background: color(darkBlue);
    }
  }
}
