$showMediaLabel: false;
// Pretty check (checkbox & radio).
$pretty-check-outer: rem(30);
$pretty-check-inner: calc(#{$pretty-check-outer} - #{rem(10)});

/// Pretty check styles (checkbox & radio).
.pretty-check {
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: rem(-9999);
  }

  input + label {
    position: relative;
    font-size: type(label, size);
    font-weight: type(label, weight);
    line-height: type(label, lineHeight);
    text-transform: type(label, textTransform);
    padding-left: calc(#{$pretty-check-outer} + #{rem(15)});
    min-height: $pretty-check-outer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      top: 0;
      left: 0;
      border: rem(1) solid color(border);
      width: $pretty-check-outer;
      height: $pretty-check-outer;
      background: #fff;
      transition: border-color .4s ease-in-out, background .3s ease-in-out;
      box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.04);
    }

    &:after {
      left: rem(5);
      top: rem(5);
      width: $pretty-check-inner;
      height: $pretty-check-inner;
      transform: scale(0);
      transition: transform .4s ease-in-out;
    }

    &:hover, &:focus {
      &:before {
        border-color: color(primary, default);
      }
    }
  }

  input {
    &[disabled] {
      &+label {
        cursor: not-allowed;
        color: color(text, default);

        &:before {
          border-color: color(border);
          background: color(disabled);
        }
      }
    }
  }

  
  input:checked + label {
    &:before {
      border-color: color(primary, default);
    }
    &:after {
      transform: scale(1);
    }
  }

  [type="checkbox"]:checked + label {
    &:before {
      background: color(primary, default);
    }
  }

  [type="checkbox"] + label {
    &:before,
    &:after {
      border-radius: radius(large);
    }

    &:after {
      content: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMDAwMTYgMTEuMTcwMUwxLjgzMDE2IDcuMDAwMDlMMC40MTAxNTYgOC40MTAwOUw2LjAwMDE2IDE0LjAwMDFMMTguMDAwMiAyLjAwMDA5TDE2LjU5MDIgMC41OTAwODhMNi4wMDAxNiAxMS4xNzAxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
    }
  }
  [type="radio"] + label {
    &:before,
    &:after {
      border-radius: radius(circle);
    }

    &:after {
      background: color(primary, default);
    }
  }

  &.pretty-check--error {
    input + label {
      color: color(error);
      
      &:before {
        border-color: color(error);
      }
    }
  }
}

.pretty-check--space {
  .pretty-check {
    margin-top: rem(20);
  }
}