$showMediaLabel: false;
$spinner-dimensions: rem(60);
$spinner-dimensions-small: rem(30);

.spinner,
.spinner span {
  border-radius: 50%;
  width: $spinner-dimensions;
  height: $spinner-dimensions;
  border-width: rem(4);
  border-style: solid;
  display: block;
}

.spinner {
  display: block;
  position: relative;
  border-color: rgba(0, 0, 0, .1);
  clear: both;

  span {
    position: absolute;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: color(primary, default);
    border-bottom-color: color(secondary, default);
    top: rem(-4);
    left: rem(-4);
    animation: rotate 1s infinite linear;
  }

  &--logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// KEYFRAMES
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}