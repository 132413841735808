$showMediaLabel: false;
////
/// @group Inputs/forms
////

$input-height: button-size(default); // ! Same as buttons

/// Input placeholder mixin
/// @param {Color} $Color [color(headline)] - color, optional.
/// @param {Color} $FontWeight [type(input, weight)] - color, optional.
@mixin input-placeholders(
  $Color: color(purple),
  $FontWeight: type(input, weight),
) {
  &::-webkit-input-placeholder {
    color: $Color;
    font-weight: $FontWeight;
  } // Default

  &:-moz-placeholder {
    color: $Color;
    font-weight: $FontWeight;
  } // Firefox 18-

  &::-moz-placeholder {
    color: $Color;
    font-weight: $FontWeight;
  } // Firefox 19+
  
  &:-ms-input-placeholder { // IE
    color: $Color;
    font-weight: $FontWeight;
  }
}

/// Input base mixin. Can be used as standalone.
@mixin input-base() {
  width: 100%;
  // height: $input-height;
  padding: rem(15 20);
  background: #fff;
  border: rem(1) solid color(border);
  border-radius: radius(large);
  box-shadow: 0 0 0 rem(3) rgba(0, 0, 0, 0.04), inset 0 rem(4) rem(5) rgba(0, 0, 0, 0.05);
  font-size: type(input, size);
  line-height: type(input, lineHeight);
  font-weight: type(input, fontWeight);
  text-align: left;
  color: color(text, default);
  -webkit-appearance: none;
  appearance: none;

  transition: padding .2s ease-in-out, border-color .4s ease-in-out;

  @include input-placeholders();
}

/// Select styles
select {
  @include input-base();
  cursor: pointer;
  padding-right: rem(40);
  transition: outline .3s ease-in-out, padding .2s ease-in-out, border-color .4s ease-in-out;

  &:focus, &:active {
    outline: 1px solid color(primary, default);
  }

  &:hover {
    border: 1px solid color(primary, default);
    color: color(primary, default);
  }
}

.input-group--select {
  &:hover {
    .input-group--select-icon {
      color: color(primary, default);
    }
  }
}

.input-group__input-wrap {
  position: relative;
}

/// Input styles
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="double"],
input[type="tel"] {
  @include input-base();
  &:focus-visible {
    outline: 1px solid color(primary, default);
  }
}

textarea {
  @include input-base();
  font-family: "GT America", "Arial", sans-serif;
  font-weight: 200;
  resize: vertical;
  height: auto;
  min-height: rem(300);
  &:focus-visible {
    outline: 1px solid color(primary, default);
  }

}

/// Number inputs
input[type='number'] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}


/// Label
label {
  display: block;
  cursor: pointer;
}
.label {
  font-size: type(groupLabel, size);
  font-weight: type(groupLabel, weight);
  line-height: type(groupLabel, lineHeight);
  text-transform: type(groupLabel, textTransform);
  margin-bottom: gutter(m);
  color: color(headline);

  // This class is used on the label when the design doesn't include a label (it is necessary for accessibility)
  &--a11y {
    font-size: 0;
    position: absolute;
    top: 0;
    left: -9999;
  }

  // ... spacing styles for labels here
}

/// Input icon. Requires a wrapping element with class .input-group__input-wrap
.input-group__icon {
  position: absolute;
  top: 50%;
  right: rem(17); 
  width: rem(20);
  height: rem(20);
  transform: translateY(-50%);
  pointer-events: none;

  svg path {
    fill: color(text, default);
  }
}

input {
  padding-right: calc(#{rem(20)} + #{rem(15)} + #{rem(15)});
  
  &[readonly] {
    background: #fff;
    cursor: pointer;
  }
}

/// Input group.

.input-group {
  position: relative;
  display: flex;
  flex-flow: column;
  
  .input-group__error {
    color: color(error);
    margin-top: rem(10);
  }
  
  .input-group__wrap {
    position: relative;
    // margin-top: auto; //we need to work this out
    
    &::after {
      position: absolute;
      top: 50%;
      right: rem(16);    
    }
  }

  .input-group__input {
    &[disabled],
    &[readonly] {
      color: color(text, default);
    }    
  }

  &:not(.input-group--error):not(.input-group--select) {
    // background: deeppink;
    .input-group__input {
      &[disabled],
      &[readonly] {
        background: color(disabled);
        border-color: color(border);
        cursor: not-allowed;
      }
    }

    // &:hover {
    //   .input-group__input {
    //     &:not([disabled], [readonly]) {
    //       border-color: color(border);
    //     }
    //   }
    // }
  }

  &.input-group--error {
    .input-group__error {
      display: block !important;
    }

    .input-group__icon svg path {
      fill: color(error);
    }

    .input-group__input {
        &:not([disabled], [readonly]) {
        border-color: color(error);
        color: color(error);
        
        @include input-placeholders(
          $Color: color(error),
        )
      }
      
      .input-group__error {
        display: block !important;
      }
    }
  }

  &.input-group--hidden {
    display: none;
  }
}

.input-group__unit {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(rem(-20), -50%);
  color: color(text, light);
}

.input-group__description {
  margin-top: rem(20);
  @extend .body3;
}

.input-group--select {
  .input-group--select-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: rem(20);
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.input-group--search {
  .input-group__input {
    padding-right: rem(68);
  }
}
