$showMediaLabel: false;
.auth__container {
  background: color(lightPurple);
  border-radius: rem(10);
  padding: rem(30);
  
  @include mq(phone-m) {
    padding: rem(60);
  }

  @include mq(tablet) {
    padding: rem(120);
  }

  @include mq(desktop-s) {
    padding: rem(80);
  }

  @include mq(desktop-m) {
    padding: rem(120);
  }
}

.auth__waiting {
  display: flex;
  flex-direction: column;
  gap: rem(30);
  justify-content: center;
  align-items: center;
}

.auth__waiting-text {
  display: flex;
  flex-direction: column;
  gap: rem(10);
}

.auth__unauthorized {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(30);
}

.auth-login {
  max-width: rem(500);
}

.auth__methods {
  display: flex;
  gap: rem(30);
  justify-content: center;
  border-bottom: rem(1) solid color(border);
  margin-bottom: rem(40);
  @include mqOnly(desktop-xs) {
    gap: rem(40);
  }
}

.auth__method-btn {
  color: color(headline);
  font-weight: 400;
  min-height: rem(50);
  &:hover {
    color: color(primary, default);
    border-bottom: rem(2) solid color(primary, default);
  }

}

.auth__method--selected {
  color: color(primary, default);
  border-bottom: rem(2) solid color(primary, default);
}

.auth-form__info {
  margin-top: rem(30);
  text-align: center;
}
.auth-form__info-text {
  font-weight: 400 !important;
}