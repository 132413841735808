$showMediaLabel: false;
////
/// @group Typography
////

/// Text theme mixin. Can be used as standalone.
@mixin text-theme(
  $FontSize, 
  $LineHeight, 
  $Color: color(text, default), 
  $FontWeight: type(body1, weight),
  $FontFamily: font(default),
  $TextTransform: none,
) {
  line-height: $LineHeight;
  color: $Color;
  font-weight: $FontWeight;
  font-size: $FontSize;
  text-transform: $TextTransform;
}

/// Text body 1 styles.
.body1 {
  @include text-theme(
    $FontSize: type(body1, size),
    $LineHeight: type(body1, lineHeight),
  );
}

/// Text body 2 styles.
.body2 {
  @include text-theme(
    $FontSize: type(body2, size),
    $LineHeight: type(body2, lineHeight),
    $FontWeight: type(body2, weight),
  );
}

/// Text body 3 styles.
.body3, .body3 p {
  @include text-theme(
    $FontSize: type(body3, size),
    $LineHeight: type(body3, lineHeight),
    $FontWeight: type(body3, weight),
    $Color: type(body3, color),
  );
}
.body3--margin p {
  margin-bottom: rem(20);

  @include mq(tablet) {
    margin-bottom: rem(30);
  }
}

//Text body 4 styles.
.body4 {
  @include text-theme(
    $FontSize: type(body4, size),
    $LineHeight: type(body4, lineHeight),
    $FontWeight: type(body4, weight),
    $Color: type(body4, color),
  );
}

/// Text body 3 styles.
.blockquote {
  @include text-theme(
    $FontSize: type(blockquote, size),
    $LineHeight: type(blockquote, lineHeight),
    $FontWeight: type(blockquote, weight),
    $Color: type(blockquote, color),
  );
}

/// Text styling.
.text-styling {
  p {
    color: color(text, default);
    @include text-theme(
      $FontSize: type(body1, size),
      $LineHeight: type(body1, lineHeight),
      $FontWeight: type(body1, weight),
      $Color: type(body1, color),
    );
  }
  
  strong, b {
    font-weight: weight(bold);
  }

  em, i {
    font-style: italic;
  }

  ul,
  ol {
    display: block;
    padding: 0;
    list-style: none;
    color: color(text, default);
    font-size: type(body1, size);
    font-weight: type(body1, weight);
    margin: 0 0 gutter(l) 0;

    li {
      position: relative;
      margin-top: rem(15);
      line-height: 1.5;

      ul, ol {
        margin-top: rem(20);
        margin-bottom: rem(20);
      }
  
      &:first-child {
        margin-top: 0;
      }

      &:before {
        position: absolute;
        left: rem(0);
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  // Unordered list
  ul {
    li {
      padding-left: rem(18);
      margin-left: rem(10);

      &:before {
        content: '';
        position: absolute;
      }

      &:before {
        content: '';
        position: absolute;
        top: rem(9);
        left: 0;
        width: rem(4);
        height: rem(4);
        border-radius: 100%;
        background: color(text, default);
      }
    
    }
  }

  // Ordered list
  ol {
    counter-reset: item;
    padding-left: 0;

    li {
      counter-increment: item;
      padding-left: rem(24);

      &:before {
        content: counter(item) '. ';
        top: 0;
        left: 0;
        color: color(text, default);
        font-family: font(default);
      }
    }
  }
}

.text-bold, strong {
  font-weight: weight(bold);
}

.text-regular {
  font-weight: weight(regular);
}

.text-light {
  font-weight: weight(light);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text--margin-bottom {
  margin-bottom: rem(5);
}
