$showMediaLabel: false;
.input-group__wrap--toggle {
  display: flex;
}

.toggle-switch {
	display: flex;
	overflow: hidden;
  @include input-base();
  width: auto;
  padding: 0;
	font-size: rem(18);
}

.toggle-switch--large {
	font-size: rem(15);

	@include mq(phone-m) {
		font-size: rem(18);
	}
}

.toggle-switch input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.toggle-switch label {
	background-color: transparent;
	color: color(text, default);
	text-align: center;
  margin: rem(3);
	border-radius: rem(3);
	transition: all 0.1s ease-in-out;
	padding: rem(12) rem(15);
}

.toggle-switch--large label {
	padding: rem(5);
	
	@include mq(tablet) {
		padding: rem(12) rem(15);
	}
}

.toggle-switch label:hover {
	cursor: pointer;
}

.toggle-switch input:checked + label {
	background-color: color(secondary, default);
  // color: color(white);
}