$showMediaLabel: false;
////
/// @group Buttons
////


$button-sizes: (
  graph: rem(30),
  default: rem(74),
  secondary: rem(60)
);

@function button-size($key) {
  @return map-get($button-sizes, $key);
}

/// Button theme mixin.
/// All paramters have default values.
/// @param {Color} $TextColor [#fff] - Text color, optional.
/// @param {Color} $HoverTextColor [#fff] - Text color - Hover, optional.
/// @param {Color} $BorderColor [color(primary, default)] - Border color, optional.
/// @param {Color} $HoverBorderColor [color(primary, hover)] - Border color - Hover, optional.
/// @param {Color} $BackgroundColor [color(primary, default)] - Background color, optional.
/// @param {Color} $HoverBackgroundColor [color(primary, hover)] - Background color - Hover, optional.
/// @param {Number} $Padding [rem(18) rem(30) rem(17) rem(30)] - Padding, optional.
/// @param {Number} $BorderRadius [radius(default)] - Border radius, optional.
/// @param {Number} $BorderWidth [rem(1)] - Border width, optional.
@mixin button-theme($TextColor: #fff,
  $HoverTextColor: #fff,

  $BorderColor: color(primary, default),
  $HoverBorderColor: color(primary, hover),

  $BackgroundColor: color(primary, default),
  $HoverBackgroundColor: color(primary, hover),

  $Padding: rem(18) rem(30) rem(18) rem(30),
  $PaddingDesktop: rem(18) rem(40) rem(18) rem(40),

  $BorderRadius: radius(default),
  $BorderWidth: rem(1),
) {
  padding: $Padding;

  @include mq(desktop-xl) {
    padding: $PaddingDesktop;
  }

  color: $TextColor;
  background-color: $BackgroundColor;

  // ! borders so the button will always match an input element in height (without the border, it will always be 1-2px off)
  border-width: $BorderWidth;
  border-color: $BorderColor;
  border-radius: $BorderRadius;

  // svg,
  // svg > path {
  //   fill: $TextColor;
  //   color: $TextColor;
  // }

  .button__hover {
    background: $HoverBackgroundColor;
    border: rem(1) solid $HoverBackgroundColor;
    box-shadow: inset 0 0 0 rem(1) $HoverBackgroundColor;
    padding: $Padding;
    color: $HoverTextColor;

    @include mq(desktop-xl) {
      padding: $PaddingDesktop;
    }
  }

  &:not([disabled]):not(.button--no-hover) {

    &:hover,
    &:active {
      .button__hover {
        animation: 0.5s cubic-bezier(0.65, 0, 0.35, 1) 0s 1 normal forwards button--in;
      }
    }
  }
}

/// Button base class.
.button {
  // display / position
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: button-size(default);

  // transform / transition
  transform: translateY(0);
  transition: color .4s ease-in-out, background-color .4s ease-in-out, border-color .4s ease-in-out, box-shadow .4s ease-in-out;

  // type
  font-family: font(default);
  font-size: type(button, size);
  line-height: type(button, lineHeight);
  font-weight: type(button, weight);
  text-transform: type(button, textTransform);

  // alignment
  vertical-align: middle;

  // border
  border-style: solid;
  border-width: rem(2);
  border-color: transparent;

  // ! this helps with getting the correct event.target in javascript clicks.
  >* {
    pointer-events: none;
  }

  svg,
  svg path {
    transition: fill .4s ease-in-out;
  }

  &:not([disabled]):active {
    transform: translateY(rem(1));
  }
}

.button__search {
  position: absolute;
  height: 100%;
  width: auto;
  padding: 0 rem(20);
  right: 0;
  color: color(primary, default);
  min-height: unset;
}

/// Button icon class.
.button__icon {
  line-height: 0;
  margin-right: gutter(s);
  flex: 0 0 rem(24);

  @include mq(desktop-xxl) {
    margin-right: gutter(m);
  }
}

.button--icon-right {
  .button__icon {
    margin-right: 0;
    margin-left: gutter(s);

    @include mq(desktop-xxl) {
      margin-right: 0;
      margin-left: gutter(m);
    }
  }
}

/// Button hover class. Extra effects on button hover - background sliding in & out.
.button__hover {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  position: absolute;
  inset: -1px;
  z-index: 1;
  border-radius: inherit;
  clip-path: inset(100% 0 0 0);
  animation: 0.3s ease-in 0s 1 normal forwards button--out;

  &::after {
    content: attr(data-text);
    display: block;
  }
}

.button--icon-right {
  .button__hover::before {
    content: attr(data-text);
    display: block;
  }

  .button__hover::after {
    display: none;
  }
}

.button[disabled] {
  .button__hover {
    display: none;
  }
}

.button--compact {
  .button__hover {
    padding: rem(17) rem(39) rem(17) rem(39) !important;
    inset: 0;
  }
}

// Button theme styles

/// Default button.
.button--default {
  @include button-theme();
}

/// Compact button.
.button--compact {
  min-height: button-size(secondary);
  border-width: 0;
}

/// Secondary button.
.button--secondary {
  @include button-theme($TextColor: color(darkBlue),
    $HoverTextColor: #fff,

    $BorderColor: color(secondary, default),
    $HoverBorderColor: color(secondary, hover),

    $BackgroundColor: color(secondary, default),
    $HoverBackgroundColor: color(secondary, hover),
  );
}

/// Third button.
.button--third {
  @include button-theme($TextColor: #fff,
    $HoverTextColor: #fff,

    $BorderColor: color(darkBlue),
    $HoverBorderColor: color(secondary, hover),

    $BackgroundColor: color(darkBlue),
    $HoverBackgroundColor: color(secondary, hover),
  );
}

// ... other button themes here

/// Border button.
.button--border {
  @include button-theme($TextColor: color(primary, default),
    $HoverTextColor: color(primary, default),

    $BorderColor: color(border),
    $HoverBorderColor: color(primary, default),

    $BackgroundColor: transparent,
    $HoverBackgroundColor: transparent,
  );


  .button__hover {
    display: none;
  }

  &:not(.button--no-hover) {
    &:hover {
      border-color: color(primary, hover);
      color: color(primary, hover);
      box-shadow: inset 0 0 0 rem(1) color(primary, hover);
    }
  }
}

/// Border button primary
.button--border-primary {
  @include button-theme($TextColor: color(primary, default),
    $HoverTextColor: color(primary, hover),

    $BorderColor: color(primary, default),
    $HoverBorderColor: color(primary, hover),

    $BackgroundColor: transparent,
    $HoverBackgroundColor: transparent,
  );


  .button__hover {
    display: none;
  }

  &:not(.button--no-hover) {
    &:hover {
      border-color: color(primary, hover);
      color: color(primary, hover);
      box-shadow: inset 0 0 0 rem(1) color(primary, hover);
    }
  }
}

.button--border.button--compact {
  padding: rem(17) rem(30) rem(17) rem(30),
}

.button--border-primary.button--compact {
  padding: rem(17) rem(30) rem(17) rem(30),
}

/// Square button.
.button--square {
  @include button-theme($TextColor: color(primary, default),
    $HoverTextColor: color(primary, hover),

    $BorderColor: color(primary, default),
    $HoverBorderColor: color(primary, hover),

    $BackgroundColor: transparent,
    $HoverBackgroundColor: transparent,

    $Padding: 0,
    $PaddingDesktop: 0,
  );

  width: button-size(secondary);
  height: button-size(secondary);
  padding: 0;
  min-height: button-size(secondary);

  .button__text {
    font-size: 0;
  }

  .button__icon {
    margin: 0;
  }

  .button__hover {
    display: none;
  }

  &:hover {
    border-color: color(primary, hover);
    box-shadow: inset 0 0 0 rem(1) color(primary, hover);
  }
}

/// Disabled.
.button[disabled] {
  background: color(disabled);
  border-color: color(disabled);
  cursor: not-allowed;

  &:hover {
    cursor: not-allowed;
    background: color(disabled) !important;
    border-color: color(disabled) !important;
    color: #fff;
  }
}

/// Full width
.button--full {
  width: 100%;
}

.button__text,
.button__hover {
  text-wrap: balance;
}

.button--text {
  .button__text {
    font-size: inherit;
  }
}

.button--back {
  border-color: color(border);
  color: color(border);
  width: rem(74);
  height: rem(74);

  .button__icon {
    transform: rotate(180deg);
  }
}

@keyframes button--in {
  0% {
    clip-path: inset(100% 0 0 0);
  }

  100% {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes button--out {
  0% {
    clip-path: inset(0 0 0 0);
  }

  100% {
    clip-path: inset(0 0 100% 0);
  }
}