$showMediaLabel: false;
////
/// @group Layout
////


/// Grid base class. This is mobile first, so the grid is 100% width (1fr) on mobile and then expands for larger screens.
.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid--small {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  @include mq(desktop-s) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

/// Grid alignment class. Used when you don't want the columns to be the same height.
.grid--align-start {
  @include mq(tablet) {
    align-items: flex-start;
  }
}

/// Grid gap class. Used to add a gap on between the columns.
.grid--gap {
  gap: gutter(s);

  @include mq(tablet) {
    gap: gutter(m);
  }

  @include mq(desktop-l) {
    gap: gutter(l);
  }
}

/// Grid gap class - vertical. Used to add a gap on the vertical axis between the columns.
.grid--gap-vertical {
  gap: gutter(s) 0;

  @include mq(tablet) {
    gap: gutter(m) 0;
  }

  @include mq(desktop-l) {
    gap: gutter(l) 0;
  }
}

/// Grid gap class - horizontal. Used to add a gap on the horizontal axis between the columns.
.grid--gap-horizontal {
  gap: 0 gutter(s);

  @include mq(tablet) {
    gap: 0 gutter(m);
  }

  @include mq(desktop-l) {
    gap: 0 gutter(l);
  }
}

/// Grid gap class - mobile only. Used to have only a gap on mobile devices.
.grid--gap-mobile {
  @include mqBetween(phone-xs, phone-m) {
    gap: gutter(m);
  }
}

.gap--elements {
  gap: gutter(m);

  @include mq(desktop-s) {
    gap: gutter(l);
  }
}
