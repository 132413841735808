$showMediaLabel: false;

.spacing--small {
  margin: rem(30) 0;

  @include mq(desktop-xl) {
    margin: rem(60) 0;
  }
}

.spacing--small-top {
  margin: rem(30) 0 0;

  @include mq(desktop-xl) {
    margin: rem(60) 0 0;
  }
}

.spacing--medium {
  margin: rem(30) 0;

  @include mq(tablet) {
    margin: rem(60) 0;
  }

  @include mq(desktop-xl) {
    margin: rem(90) 0;
  }
}

.spacing--large {
  margin: rem(60) 0;

  @include mq(desktop-xl) {
    margin: rem(180) 0;
  }
}

.spacing--large-top {
  margin: rem(60) 0 0;

  @include mq(desktop-xl) {
    margin: rem(180) 0 0;
  }
}