$showMediaLabel: false;
.tippy-box {
  padding: rem(20);
  font-weight: 300;
  font-size: rem(15);
  line-height: rem(25);
}

.tippy-box[data-theme~='blue'] {
  background-color: color(primary, default);
  color: color(white);
}

.tippy-box[data-theme~='blue'][data-placement^='top']{
  .tippy-arrow::before {
    border-top-color: color(primary, default);
  } 
}

.tippy-box[data-theme~='blue'][data-placement^='bottom']{
  .tippy-arrow::before {
    border-bottom-color: color(primary, default);
  } 
}

.tippy-box[data-theme~='blue'][data-placement^='left']{
  .tippy-arrow::before {
    border-left-color: color(primary, default);
  } 
}
.tippy-box[data-theme~='blue'][data-placement^='right']{
  .tippy-arrow::before {
    border-right-color: color(primary, default);
  } 
}

.tooltip {
  display: flex;
  align-self: center;
  margin-left: auto;
  color: color(secondary, default);
}
