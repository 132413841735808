$showMediaLabel: false;
.richtext-tooltip {
  color: color(primary, default);
  background-color: rgba(color(primary, default), 0.1);
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    color: color(white);
    background-color: color(secondary, default);
  }
}