$showMediaLabel: false;

.box {
  border-radius: radius(xlarge);
  padding: rem(20);

  @include mq(desktop-xs) {
    padding: rem(40);
  }
}

.box--style-blue {
  background-color: color(lightPurple);
}

.box--align-column {
  display: flex;
  flex-flow: column;
  gap: rem(30);
}

.box--align-row {
  display: flex;
  flex-flow: row wrap;
  gap: rem(30);
}

.box__item {
  display: flex;
  flex-direction: column;
  gap: rem(8);
}

.box__item--has-links {
  margin-top: rem(40);
  gap: rem(16);
}

.box__links {
  display: flex;
  flex-direction: column;
  gap: rem(16);

  @include mq(tablet) {
    flex-direction: row;
  }
}

