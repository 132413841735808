$showMediaLabel: false;
.text-color-blue {
  color: color(primary, default);
}

.text-color-darkblue {
  color: color(darkBlue);
}

.text-color-pink {
  color: color(secondary, default);
}

.text-color-green {
  color: color(green);
}

.text-color-teal {
  color: color(teal);
}

.text-light-grey {
  color: color(text, light);
}