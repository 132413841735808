$showMediaLabel: false;

.subpage__content {
  margin-top: rem(32);

  @include mq(desktop-m) {
    margin-top: rem(45);
  }

  &>*:last-child {
    margin-bottom: rem(30);

    @include mq(desktop-m) {
      margin-bottom: rem(60);
    }
  }
}

.subpage__headline {
  margin-bottom: rem(20);

  @include mq(desktop-s) {
    margin-bottom: rem(30);
  }
}

.subpage__subtitle {
  margin-bottom: rem(20);

  @include mq(desktop-s) {
    margin-bottom: rem(30);
  }
}

.subpage__summary {
  margin-bottom: rem(30);

  @include mq(desktop-s) {
    margin-bottom: rem(60);
  }
}

.subpage__button {
  margin-top: rem(20);

  @include mq(desktop-s) {
    margin-top: rem(60);
  }
}
