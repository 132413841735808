$showMediaLabel: false;
////
/// @group Typography
////

/// Richtext class styles. This is used for all blocks/pages that implement the richtext editor in Umbraco.
.richtext {
  h1 {
    @extend .headline1;
    margin-bottom: gutter(m);
  }

  h2 {
    @extend .headline2;
    margin-bottom: gutter(m);
  }

  h3 {
    @extend .headline3;
    margin-bottom: gutter(m);
  }

  h4 {
    @extend .headline4;
    margin-bottom: gutter(m);
  }
  
  p {
    // Spacing
    margin-bottom: gutter(m);
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  @extend .text-styling;
  
  table {
    @extend .table;
    width: 100%;

    //* mobile scroll
    @include mqBetween(phone-xs, tablet) {
      max-width: 100%;
      display: block;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        height: rem(8);
      }
    }
  }

  //* when the client has copy and pasted and the table is wrapped in a div
  //* has to be scrollable at all sizes due to huge tables
  div:has(table) {
    max-width: 100%;
    display: block;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      height: rem(8);
    }
  }
}