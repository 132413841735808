$showMediaLabel: false;
// All of the html/body styles are required so the styles will behave as expected.
html,
body {
  position: relative;
  font-size: 100%;
  height: 100%;
}

html {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: font(default);
  font-size: 100%;
  line-height: 1;
  text-align: left;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  text-size-adjust: none;
  color: color(text, default);
  background: color(white);

  * {
    box-sizing: border-box;

    &:focus,
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }
}

a,
button {
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

a {
  color: color(primary, default);

  &:hover {
    color: color(primary, hover);
  }
}

// focus visible styles (see focus-visible npm package)
*:focus:not(:focus-visible) {
  outline: none;
}

.hide {
  display: none !important;
}

.preload * {
  animation-duration: 0s !important;
  transition: none !important;
}

// CookieHub
.ch2-icon {
  @include mqBetween(phone-xs, phone-m) {
    display: none !important;
  }
}

[data-scroll-item] {
  will-change: transform;
  // transform: translateY(200);
  // opacity: 0;
}

.h--disable-scroll {
  max-height: 100vh;
  overflow-y: hidden;
}

html {
  scroll-behavior: smooth;
}

.form__recaptcha {
  margin-bottom: rem(30);
  max-width: 100%;
}

@include mqBetween(phone-xs, phone-m) {
  .g-recaptcha {
    width: rem(100);
    transform: scale(0.7);
    transform-origin: 0 0;
  }
}

@import 'container';
@import 'button';
@import 'print';
@import 'layout';
@import 'screen-readers';
@import 'scrollbar';
@import 'spacing';
@import 'tables';
@import 'subpage';
@import 'my-pages';
@import 'loginpage';
@import 'tooltip';
@import 'richtext-tooltip';
@import 'box';

@import 'forms/form-box';
@import 'forms/input';
@import 'forms/pretty-check';
@import 'forms/box-radio';
@import 'forms/toggle-switch';

@import 'typography/font-face'; // Locally hosted fonts
@import 'typography/headline';
@import 'typography/subtitle';
@import 'typography/text-styling';
@import 'typography/text-colors';
@import 'typography/richtext';
