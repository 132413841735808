$showMediaLabel: false;

.layout {
  margin-bottom: rem(60);
  
  @include mq(desktop-xl) {
    margin-bottom: rem(120);
  }
}

.layout__sidebar {
  display: none;

  @include mq(desktop-m) {
    display: block;
    margin-top: rem(112);
    grid-column: span 3;
  }
}

.layout__divider {
  display: none;

  @include mq(desktop-m) {
    display: block;
    grid-column: 4 / span 1;
  }
}

.layout__content {
  grid-column: span 12;

  @include mq(desktop-m) {
    padding-top: rem(32);
    grid-column: 5 / span 8;
  }

  @include mq(desktop-xxl) {
    padding-top: rem(36);
  }

  @include mq(ultrawide) {
    padding-top: rem(45);
  }
}

.layout__thin {
  grid-column: span 12;

  @include mq(desktop-m) {
    grid-column: 3 / span 8;
  }
}

.layout__column {
  margin-top: rem(30);

  @include mq(desktop-m) {
    margin-top: 0;
  }

  &>*:first-child {
    margin-top: 0;

    @include mq(desktop-m) {
      margin-top: rem(60);
    }

    @include mq(desktop-l) {
      margin-top: rem(90);
    }
  }
}

.layout__half {
  grid-column: span 12;
  margin-top: rem(30);

  @include mq(desktop-s) {
    grid-column: span 6;
  }

  @include mq(desktop-m) {
    margin-top: 0;
  }

  &--grid {
    @include mq(desktop-l) {
      display: grid;
      gap: gutter(l);
      grid-template-columns: repeat(6, minmax(0, 1fr));
      align-content: flex-start;
    }
  }

  &--width {
    background-color: lightblue;

    @include mq(desktop-l) {
      &-full {
        grid-column: span 6;
      }
    }

    &-indent {
      margin-bottom: rem(30);

      @include mq(desktop-l) {
        grid-column: span 5;
      }
    }

  }
}

.layout--fixed {
  position: relative;

  .layout__sidebar {
    position: fixed;
  }
}